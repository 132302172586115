<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="1200"
    :height="600"
    :loading="loading"
    @close="onClose"
  >
    <template #footer>
      <div class="bottoms">
        <div style="width: 800px">
          <a-row type="flex" justify="space-around" align="middle">
            <a-col :span="5">
              <s-tree-select
                :valueCol="24"
                title="菜品类型"
                :show-title="false"
                :margin="0"
                :fieldNames="replaceFields"
                v-model:value="queryParams.DishTypeId"
                :options="treeData"
              />
            </a-col>
            <a-col :span="5">
              <a-input
                v-model:value="queryParams.Name"
                placeholder="菜品名称"
              />
            </a-col>
            <a-col :span="2">
              <a-button @click="getDataList(1)">搜索</a-button>
            </a-col>
            <a-col :span="10" offset="1">
              <a-pagination
                :total="page.total"
                size="small"
                simple
                v-model:current="page.page"
                v-model:page-size="queryParams.Limit"
                :hide-on-single-page="true"
                :showQuickJumper="false"
                :showSizeChanger="false"
                @change="getDataList"
              >
                <template #itemRender="{ page, type }">
                  <a v-if="type === 'prev'" class="ant-pagination-item-link">
                    <left-outlined />
                  </a>
                  <a
                    v-else-if="type === 'next'"
                    class="ant-pagination-item-link"
                  >
                    <right-outlined />
                  </a>
                  <span v-else>{{ page }}</span>
                </template>
              </a-pagination>
            </a-col>
          </a-row>
        </div>

        <a-button type="primary" :disabled="!dish" @click="onConfirmFun">
          确定
        </a-button>
      </div>
    </template>
    <a-spin tip="Loading..." :spinning="loading">
      <a-row :gutter="[5, 5]">
        <a-col :span="4" v-for="(v, k) in list" :key="k">
          <div
            :class="{
              border: true,
              on: dish && dish.id === v.id,
            }"
            @click="onChooseOne(k)"
          >
            <a-card hoverable>
              <template #cover>
                <div class="image">
                  <img alt="example" :src="$filters.image(v.pic)" />
                </div>
              </template>
              <a-card-meta :description="'售价：¥' + v.sellingPrice">
                <template #title>
                  <div class="title">
                    <div class="zhangweifu">占位符</div>
                    <div class="dish-title">{{ v.name }}</div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </a-spin>
    <e-modal
      :visible="showModal"
      :title="title2"
      :width="300"
      :loading="loading"
      @ok="onChoose"
      @close="onCloseModal"
    >
      <div>
        <s-select
          :labelCol="8"
          :valueCol="16"
          title="赠送台位"
          v-model:value="modelRef.tableId"
          :options="tableOptions"
        />
        <s-input-number
          :labelCol="8"
          :valueCol="16"
          title="数量"
          v-model:value="modelRef.count"
        />

        <s-select
          :labelCol="8"
          :valueCol="16"
          title="状态"
          v-model:value="modelRef.state"
          :options="stateOptions"
        />
        <s-select
          v-if="modelRef.state === 1"
          :labelCol="8"
          :valueCol="16"
          title="赠菜用户"
          v-model:value="modelRef.operatorId"
          :options="operatorOptions"
        />

        <s-select
          :labelCol="8"
          :valueCol="16"
          title="上菜"
          v-model:value="modelRef.timing"
          :options="[
            { label: '即起', value: 0 },
            { label: '等叫', value: 1 },
          ]"
        />

        <e-textarea
          :labelCol="8"
          :valueCol="16"
          title="备注"
          v-model:value="modelRef.remark"
        />
      </div>
    </e-modal>
  </e-modal>
</template>

<script>
import { defineComponent, ref, toRaw, reactive } from 'vue'
import { DishClass } from '@/apis/dish'
import { OrderItemClass } from '@/apis/order'
import page from '@/mixins/page'
import { Form, message } from 'ant-design-vue'
import { dataToTree } from '@/utils'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

const useForm = Form.useForm
const api = new DishClass()
const orderItem = new OrderItemClass()
export default defineComponent({
  mixins: [page],
  emits: ['ok'],
  components: {
    LeftOutlined,
    RightOutlined
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const selects = ref([])

    const tableOrder = ref()
    const dish = ref()
    const title = ref('加菜')
    const title2 = ref('加菜')
    const tableOptions = ref([])
    const showModal = ref(false)

    const queryParams = ref({
      Limit: 60
    })

    const onClose = () => {
      resetFields()
      dish.value = null
      showModal.value = false
      loading.value = false
      visible.value = false
    }

    const onConfirmFun = () => {
      showModal.value = true
    }
    const onCloseModal = () => {
      loading.value = false
      showModal.value = false
    }

    const onChoose = () => {
      console.log(toRaw(modelRef))

      const data = toRaw(modelRef)
      if (!data.tableId) {
        delete data.tableId
      }
      validate()
        .then(() => {
          loading.value = true
          orderItem.add(data).then(() => {
            message.success(data.state === 0 ? '加菜成功' : '赠菜成功')
            onClose()
            emit('ok')
          })
        })
        .catch((e) => {
          e.errorFields.map((e) => {
            e.errors.map((x) => {
              message.error(x)
            })
          })
        })
    }

    const modelRef = reactive(orderItem.modelRef)
    const rulesRef = reactive(orderItem.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )
    const treeData = ref([])
    const operatorOptions = ref([])

    return {
      visible,
      loading,
      selects,
      validateInfos,
      modelRef,
      tableOrder,
      dish,
      title,
      queryParams,
      tableOptions,
      treeData,
      replaceFields: {
        value: 'id',
        key: 'id'
      },
      stateOptions: [
        {
          label: '正常',
          value: 0
        },
        {
          label: '赠送',
          value: 1
        }
      ],
      operatorOptions,
      showModal,
      title2,

      validate,
      onClose,
      resetFields,
      onChoose,
      onConfirmFun,
      onCloseModal
    }
  },

  methods: {
    async getManagersAndCurrUser () {
      const list =
        this.$store.state.data.ALL_MANAGES.length > 0
          ? this.$store.state.data.ALL_MANAGES
          : await this.$store.dispatch('GetManagersAndCurrUser')

      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.modelRef.operatorId = this.$store.state.user.userInfo.id
      this.operatorOptions = vlist
    },
    async getCategorys () {
      const list =
        this.$store.state.data.ALL_DISHTYPES.length > 0
          ? this.$store.state.data.ALL_DISHTYPES
          : await this.$store.dispatch('GetAllDishTypes')

      const data = dataToTree(list, 0)
      this.treeData = data
    },
    async onGetAllTable () {
      const list = await this.$store.dispatch('GetAllTable')
      const list2 = list.filter(
        (x) => x.state === 2 && x.id !== this.tableOrder.table.id
      )
      const vlist = []
      list2.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      console.log(vlist, this.tableOrder.table.id, list)
      this.tableOptions = vlist
    },
    open (formData, orderId) {
      console.log(formData)
      this.tableOrder = formData
      this.modelRef.orderId = orderId
      this.getDataList(1)

      this.onGetAllTable()
      this.getCategorys()
      this.getManagersAndCurrUser()
      this.visible = true
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
      })
    },
    onChooseOne (index) {
      const row = this.list[index]
      this.dish = row

      this.modelRef.dishId = row.id

      this.title2 = row.name
      this.title =
        '已选' +
        ['菜品', '台位费', '前厅商品', '损耗'][row.type] +
        '：' +
        row.name
    }
  }
})
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  height: 150px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
}
.bottoms {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.border {
  border: 1px solid transparent;
  &.on {
    border-color: #000;
  }
}
.title {
  position: relative;

  .zhangweifu {
    visibility: hidden;
    height: 40px;
  }
  .dish-title {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    line-height: 20px;
  }
}
</style>
