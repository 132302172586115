<template>
  <div class="table-content">
    <div class="search-btns">
      <div :class="{ btn: true, all: state === -1 }" @click="onSearch(-1)">
        全部
      </div>
      <div :class="{ btn: true, empty: state === 0 }" @click="onSearch(0)">
        空闲
      </div>
      <div :class="{ btn: true, prede: state === 1 }" @click="onSearch(1)">
        预定
      </div>
      <div :class="{ btn: true, eating: state === 2 }" @click="onSearch(2)">
        就餐
      </div>

      <a-date-picker
        v-model:value="bookDate"
        @change="onChangeDate"
        allowClear
        style="margin-left: 15px"
      />
      <div style="margin-left: 15px">
        <s-radio v-model:value="timeType" :margin="[0]" :options="[{label: '全天', value: 0},{label: '午餐', value: 1},{label: '晚餐', value: 2}]" @ok="onChnageTimeType" />
      </div>

    </div>

    <div
      :class="{
        tables: true,
        on: true,
      }"
    >
      <div class="table-list">
        <a-spin :spinning="loading">
          <div
            class="cover"
            v-if="ifChangeTable"
            @click="onCloseChangeTable"
          ></div>
          <ul
            :class="{
              'on-fixed': ifChangeTable,
            }"
          >
            <li
              v-for="(v, k) in list"
              :key="k"
              :class="{
                on: tableInfo && tableInfo.id === v.table.id,
              }"
            >
              <a
                :class="'state-' + v.table.state"
                @click.stop="onClickTable(v)"
              >
                <div class="li-title">{{ v.table.name }}</div>
                <QrcodeOutlined class="qrcode" @click.stop="$refs.qrcode.open(v.table)" />
                <div class="li-waiter">
                  {{
                    v.order
                      ? v.order.waiterName
                        ? "服务员：" + v.order.waiterName
                        : "请选择服务员"
                      : ""
                  }}
                </div>
                <div class="li-info">
                  <div class="info-time" v-if="v.table.state === 1">
                    <icon-font type="icon-31shijian" />
                    {{ $filters.formatDate(v.order.bookTime, "HH:mm") }}
                  </div>
                  <div class="info-money" v-if="v.table.state > 0">
                    ¥ {{ v.order.payAmount }}  ( ¥<span style="color: #f50">{{v.order.caijinAmount}}</span>)
                  </div>
                </div>
                <div class="li-info-2">
                  <div class="info-seat">
                    <icon-font type="icon-shafa" />
                    {{ v.table.peopleCount }}
                  </div>
                  <div class="info-people" v-if="v.table.state > 0">
                    <icon-font type="icon-yonghu2" style="font-size: 13px" />
                    {{ v.order.peopleCount }}
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </a-spin>

        <a-affix :offset-bottom="30">
          <div class="grid-box">
            <span>预订+就餐订单数量：{{ orderCount }}</span>
            <span class="empty">空房间</span>
            <span class="pre">已预订</span>
            <span class="arrive">已就餐</span>
          </div>
        </a-affix>
      </div>
      <div class="table-info" v-if="showInfo">
        <div class="form-content">
          <!-- 空房间，可预约，可就餐 -->
          <div class="form" v-if="tableInfo.state === 0">
            <div class="info">
              <div class="table-title">
                {{ tableInfo.name }}
              </div>
              <CInfo
                title="房间容量"
                :labelCol="7"
                :valueCol="12"
                :value="tableInfo.peopleCount"
              />
            </div>
            <div class="pre-btn arrive" @click="onArriveOrder">就餐</div>
            <div class="pre-btn" @click="onPreOrder">预约</div>
          </div>
          <div class="form" v-if="tableInfo.state === 1">
            <PreOrderInfo :orderId="orderId" @change="onGetDataList" />
          </div>
          <div class="form" v-if="tableInfo.state === 2">
            <OrderInfo
              ref="orderInfo"
              :orderId="orderId"
              @change="onGetDataList"
            />
          </div>
          <div class="pre-form" v-if="isPre">
            <PreForm
              ref="preForm"
              :table="tableInfo"
              :loading="submiting"
              @ok="onSubmitPreOrder"
            />
          </div>

          <div class="pre-form" v-if="isArrive">
            <ArriveForm
              ref="arriveForm"
              :table="tableInfo"
              :loading="submiting"
              @ok="onSubmitPreOrder"
            />
          </div>

          <div class="pre-order-list" v-if="showPreOrder" @click="onOpenRecord">
            <div
              :class="{
                'pre-order': true,
                on: showPreOrderList,
              }"
              @click.stop="() => false"
            >
              <div v-if="preOrderList.length === 0">
                <a-empty :image="simpleImage" />
              </div>
              <ul>
                <li v-for="(v, k) in preOrderList" :key="k">
                  <div class="li-title">
                    <span>
                      {{ $filters.formatDate(v.bookTime, "YYYY-MM-DD HH:mm") }}
                    </span>
                    <span style="margin-left: 10px">
                      <icon-font
                        type="icon-yonghu2"
                        style="font-size: 13px; color: #999"
                      />
                      {{ v.peopleCount }}
                    </span>
                  </div>
                  <div class="li-user">
                    <span>
                      {{ v.customerName }}
                      ({{ ["未知", "先生", "女士"][v.customerSex] }})
                    </span>
                    <span style="margin-left: 10px">
                      <MobileOutlined />
                      {{ v.customerMobile }}
                    </span>
                  </div>
                  <div class="li-combo">
                    <span>{{ v.comboName }}</span>
                    <span class="canbiao">¥{{ v.comboPrice }}元/人</span>
                  </div>
                  <div class="li-actions">
                    <div class="action">
                      <a-spin :spinning="sending">
                        <a @click.stop="onSendNotice(v.id)">
                          <icon-font type="icon-duanxin" />
                          短信提醒
                        </a>
                      </a-spin>
                    </div>
                    <div class="action">
                      <a @click.stop="unSubscribe(v.id)">
                        <CloseSquareOutlined />
                        退订
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- 空房间底部按钮 -->
          <div class="bottom-btns" v-if="tableInfo.state === 0">
            <div class="btn btn-1">
              <a @click.stop="onOpenRecord">
                <icon-font type="icon-shenqingjilu" />
                更多预约
              </a>
            </div>
          </div>

          <!-- 已预约底部按钮 -->
          <div class="bottom-btns" v-if="tableInfo.state === 1">
            <div class="btn btn-1">
              <a-spin :spinning="orderTypeLoading.arrive">
                <a @click.stop="arriveStore(orderId)">
                  <icon-font type="icon-daodian" />
                  到店
                </a>
              </a-spin>
            </div>
            <div class="btn btn-2">
              <a-spin :spinning="sending">
                <a @click.stop="onSendNotice(orderId)">
                  <icon-font type="icon-duanxin" />
                  短信提醒
                </a>
              </a-spin>
            </div>
            <div class="btn btn-2">
              <a @click.stop="onOpenRecord">
                <icon-font type="icon-shenqingjilu" />
                更多预约
              </a>
            </div>
            <div class="btn btn-1">
              <a @click.stop="onToggleMore">
                <icon-font
                  type="icon-gengduoshezhi"
                  :class="{
                    rotate: moreAction,
                  }"
                />
                更多
              </a>
            </div>
          </div>

          <!-- 就餐中底部按钮 -->
          <div class="bottom-btns" v-if="tableInfo.state === 2">
            <div class="btn btn-1">
              <a-spin :spinning="orderTypeLoading.account">
                <a
                  @click.stop="$refs.accountOrder.open(orderId, tableOrderInfo)"
                >
                  <icon-font type="icon-xianjinqingfenjiesuan" />
                  结算
                </a>
              </a-spin>
            </div>
            <div class="btn btn-1">
              <a @click.stop="$refs.addDish.open(tableOrderInfo, orderId)">
                <icon-font type="icon-jiacai" />
                加菜
              </a>
            </div>
            <div class="btn btn-2">
              <a @click.stop="$refs.tempDishe.open(tableOrderInfo, orderId)">
                <icon-font type="icon-jiacai" />
                临时菜
              </a>
            </div>
            <div class="btn btn-1">
              <a @click.stop="onToggleMore">
                <icon-font
                  type="icon-gengduoshezhi"
                  :class="{
                    rotate: moreAction,
                  }"
                />
                更多
              </a>
            </div>
          </div>
        </div>
        <div
          :class="{
            'other-btns': true,
            on: moreAction,
          }"
        >
          <div
            class="btn"
            v-if="tableInfo.state === 1 || tableInfo.state === 2"
            @click="onChangeTable"
          >
            换台
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 1 || tableInfo.state === 2"
            @click="onPreOrder"
          >
            预约
          </div>
          <div class="btn" v-if="tableInfo.state === 1" @click="onArriveOrder">
            就餐
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 1"
            @click="unSubscribe(orderId)"
          >
            退订
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 1"
            @click="nonArrival(orderId)"
          >
            未到
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 2"
            @click.stop="onOpenRecord"
          >
            更多预约
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 2"
            @click.stop="prientOrder"
          >
            打印
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 2"
            @click.stop="splitOrder"
          >
            <div>AA</div>
            结算
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 2"
            @click.stop="$refs.countOrder.open(tableOrderInfo)"
          >
            连台结算
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 2 && tableOrderInfo.order.isConnect"
            @click.stop="onCancelConnect(orderId)"
          >
            取消连台
          </div>
          <div
            class="btn"
            v-if="tableInfo.state === 2 && !tableOrderInfo.order.isConnect"
            @click.stop="onCancel(orderId)"
          >
            作废
          </div>
        </div>
      </div>
    </div>

    <AddDish ref="addDish" @ok="onAddDish" />
    <TempDishe ref="tempDishe" @ok="onAddDish" />
    <AccountOrder ref="accountOrder" @ok="onPay" />

    <CountOrder ref="countOrder" @ok="onRefresh" />

    <Qrcode ref="qrcode" />

    <e-modal
      :visible="showChoosePrint"
      title="选择打印类型"
      :width="300"
      :footer="false"
      @close="onCloseChoosePrint"
    >
      <div class="print-types">
        <div class="btn" @click="preAccountPrint">打印预结算单</div>
        <div class="btn" @click="comboPrint">打印菜单</div>
      </div>
    </e-modal>

    <e-modal
      :visible="showAAModal"
      title="AA结账拆单"
      :width="300"
      :loading="spliting"
      @ok="onSplitOrder"
      @close="onCloseAAModal"
    >
      <div v-if="tableOrderInfo && tableOrderInfo.order && !loading">
        <e-info
          :labelCol="10"
          :valueCol="12"
          title="订单金额"
          :value="tableOrderInfo.order.payAmount"
        />
        <e-input-number
          title="支付金额"
          v-model:value="splitPayAmount"
          :min="0"
          :max="tableOrderInfo.order.payAmount"
        />
      </div>
    </e-modal>
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { message, Modal, Empty } from 'ant-design-vue'
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  MobileOutlined,
  CloseSquareOutlined,
  QrcodeOutlined
} from '@ant-design/icons-vue'
import { TableClass } from '@/apis/table'
import PreForm from '@/components/counter/forms/PreOrder'
import ArriveForm from '@/components/counter/forms/ArriveForm'
import PreOrderInfo from '@/components/counter/forms/PreOrderInfo'
import OrderInfo from '@/components/counter/forms/OrderInfo'
import AddDish from '@/components/counter/forms/AddDish'
import TempDishe from '@/components/counter/forms/TempDishe'
import AccountOrder from '@/components/counter/forms/AccountOrder'
import CountOrder from '@/components/counter/forms/CountOrder'
import { OrderClass } from '@/apis/order'
import dayjs from 'dayjs'
import CInfo from '@/components/counter/form/Info'
import store from '@/store'
import Qrcode from '@/components/forms/table/Qrcode'

const table = new TableClass()
const order = new OrderClass()
export default defineComponent({
  setup () {
    const loading = ref(false)
    const submiting = ref(false)
    const sending = ref(false)
    const orderTypeLoading = ref({
      arrive: false,
      account: false,
      nonArrival: false,
      unSubscribe: false
    })

    const moreAction = ref(false)
    const showInfo = ref(false)
    const tableOrderInfo = ref()
    const tableInfo = ref()
    const orderId = ref()
    const preInfo = ref()

    const state = ref(-1)
    const orderType = ref(-1)
    const isPre = ref(false) // 是否预约
    const isArrive = ref(false) // 是否就餐
    const showPreOrder = ref(false)
    const showPreOrderList = ref(false)
    const preOrderList = ref([])
    const showChoosePrint = ref(false)

    const timer = ref(0)

    const ifChangeTable = ref(false)
    const orderCount = ref(0)

    const timeType = ref(0)
    const bookDate = ref(dayjs())
    store.commit('SET_BOOKDATE', dayjs().format('YYYY-MM-DD'))
    const list = ref([])
    const params = ref({})
    const onGetDataList = (callBackFun = null) => {
      loading.value = true

      table.all(params.value).then((resp) => {
        list.value = resp
        loading.value = false
        typeof callBackFun === 'function' && callBackFun(resp)
      })
    }

    const onGetDataList2 = (callBackFun = null) => {
      table.all(params.value).then((resp) => {
        list.value = resp
        typeof callBackFun === 'function' && callBackFun(resp)
      })
    }

    const onClickTable = (e) => {
      if (ifChangeTable.value) {
        onChangeTableFun(e.table)
        return
      }
      showInfo.value = true
      tableInfo.value = e.table
      tableOrderInfo.value = e

      if (e.table.state === 0) {
        orderType.value = -1
      } else if (e.table.state === 1) {
        orderType.value = 0
        orderId.value = e.order.id
      } else if (e.table.state === 2) {
        orderType.value = 1
        orderId.value = e.order.id
      }

      isPre.value = false
      isArrive.value = false
      moreAction.value = false
      showPreOrder.value = false
      preOrderList.value = []
    }

    const onChangeTableFun = (table) => {
      Modal.confirm({
        title: '提示！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '是否将台位换到' + table.name
        ),
        onOk () {
          order
            .changeTable(tableOrderInfo.value.order.id, table.id)
            .then(() => {
              onCloseChangeTable()
              onGetDataList((resp) => {
                tableInfo.value = resp.filter(
                  (x) => x.table.id === tableInfo.value.id
                )[0].table
              })
            })
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const onSearch = (e) => {
      params.value.State = e
      if (e === -1) {
        delete params.value.State
      }

      state.value = e
      onGetDataList()
    }

    const onChangeDate = (e) => {
      params.value.BookDate = e.format('YYYY-MM-DD')
      store.commit('SET_BOOKDATE', e.format('YYYY-MM-DD'))
      onGetDataList()
    }

    const onChnageTimeType = (e) => {
      console.log(e)

      params.value.DiningType = e
      onGetDataList()
    }

    const onToggleMore = () => {
      moreAction.value = !moreAction.value
    }

    const onArriveOrder = () => {
      isArrive.value = true
    }

    const onPreOrder = () => {
      isPre.value = true
    }

    const onChange = (e) => {
      console.log(e)
    }

    const onSendNotice = (id) => {
      sending.value = true
      order
        .notice(id)
        .then(() => {
          message.success('短信发送成功')
          sending.value = false
        })
        .catch(() => {
          sending.value = false
        })
    }

    const arriveStore = (id) => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(QuestionCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认客户是否到店就餐'
        ),
        okText: '确认到店',
        onOk () {
          console.log('OK')
          orderTypeFun(id, 1)
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const unSubscribe = (id) => {
      Modal.confirm({
        title: '警告！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认客户是否取消预订'
        ),
        okText: '确认退订',
        onOk () {
          console.log('OK')
          orderTypeFun(id, 2)
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const nonArrival = (id) => {
      Modal.confirm({
        title: '警告！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认客户是否未到达本店'
        ),
        okText: '确认未到',
        onOk () {
          console.log('OK')
          orderTypeFun(id, 3)
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const onCancel = (id) => {
      Modal.confirm({
        title: '警告！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认是否作废订单'
        ),
        okText: '确认作废',
        onOk () {
          console.log('OK')
          orderTypeFun(id, 6)
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const onCancelConnect = (id) => {
      Modal.confirm({
        title: '警告！',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请确认是否取消连台结算'
        ),
        okText: '确认取消',
        onOk () {
          console.log('OK')
          order.cancelConnectOrder(id).then(() => {
            message.success('取消连台结算成功！')

            onGetDataList((resp) => {
              tableInfo.value = resp.filter(
                (x) => x.table.id === tableInfo.value.id
              )[0].table
            })

            onGetPreOrderList()
          })
        },

        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const accountOrder = () => {
      console.log('订单结算')
    }
    const prientOrder = () => {
      console.log('订单打印')
      showChoosePrint.value = true
    }
    const addOrderDish = () => {
      console.log('加菜')
    }

    const orderTypeFun = (id, type) => {
      order.orderType(id, type).then(() => {
        message.success(
          '修改订单状态为：' +
            ['预订成功', '客户已就餐', '客户退订', '客户未到', '已支付', '未知', '已作废'][type]
        )

        isPre.value = false
        isArrive.value = false

        onGetDataList((resp) => {
          tableInfo.value = resp.filter(
            (x) => x.table.id === tableInfo.value.id
          )[0].table
        })

        onGetPreOrderList()
      })
    }

    const onGetPreOrderList = () => {
      order.preOrder(tableInfo.value.id).then((resp) => {
        preOrderList.value = resp
      })
    }

    const onOpenRecord = () => {
      showPreOrder.value = !showPreOrder.value

      if (showPreOrder.value) {
        onGetPreOrderList()
      }

      setTimeout(() => {
        showPreOrderList.value = showPreOrder.value
      }, 10)
    }

    const onCloseChoosePrint = () => {
      showChoosePrint.value = false
    }

    const preAccountPrint = () => {
      order.preAccountPrint(orderId.value).then(() => {
        message.success('预结算单打印请求发送成功')

        onCloseChoosePrint()
      })
    }
    const comboPrint = () => {
      order.comboPrint(orderId.value).then(() => {
        message.success('菜单打印请求发送成功')

        onCloseChoosePrint()
      })
    }

    const onChangeTable = () => {
      ifChangeTable.value = true
    }

    const onCloseChangeTable = () => {
      ifChangeTable.value = false
    }

    const onGetTableOrderCount = () => {
      table.orderCount(bookDate.value.format('YYYY-MM-DD')).then((resp) => {
        orderCount.value = Number(resp)
      })
    }

    const showAAModal = ref(false)
    const spliting = ref(false)
    const splitPayAmount = ref(0)
    const splitOrder = () => {
      spliting.value = false
      showAAModal.value = true
    }
    const onCloseAAModal = () => {
      spliting.value = false
      showAAModal.value = false
    }

    onGetDataList()
    onGetTableOrderCount()

    return {
      loading,
      params,
      list,
      showInfo,
      tableInfo,
      moreAction,
      preInfo,
      submiting,
      state,
      orderType,
      orderId,
      sending,
      orderTypeLoading,
      isPre,
      showPreOrder,
      showPreOrderList,
      preOrderList,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      tableOrderInfo,
      isArrive,
      showChoosePrint,
      timer,
      ifChangeTable,
      bookDate,
      timeType,
      orderCount,
      showAAModal,
      spliting,
      splitPayAmount,

      onGetDataList,
      onGetDataList2,
      onClickTable,
      onSearch,
      onChangeDate,
      onChnageTimeType,
      onToggleMore,
      onArriveOrder,
      onPreOrder,
      onChange,
      onSendNotice,
      unSubscribe,
      onCancel,
      nonArrival,
      arriveStore,
      accountOrder,
      prientOrder,
      addOrderDish,
      onOpenRecord,
      onGetPreOrderList,
      onCloseChoosePrint,
      preAccountPrint,
      comboPrint,
      onChangeTable,
      onCloseChangeTable,
      onGetTableOrderCount,
      splitOrder,
      onCloseAAModal,
      onCancelConnect
    }
  },

  components: {
    PreForm,
    PreOrderInfo,
    OrderInfo,
    CInfo,
    MobileOutlined,
    CloseSquareOutlined,
    AddDish,
    TempDishe,
    AccountOrder,
    ArriveForm,
    CountOrder,
    QrcodeOutlined,
    Qrcode
  },

  created () {
    this.$store.dispatch('GetAllCombos')
  },

  mounted () {
    this.$nextTick(() => {
      this.timer = setInterval(() => {
        this.onGetDataList2()
        this.onGetTableOrderCount()
      }, 5000)
    })
  },

  unmounted () {
    clearInterval(this.timer)
  },

  methods: {
    onSubmitPreOrder (e) {
      const data = { ...e }

      const orderType = data.orderType
      console.log(orderType)
      if (orderType === 0) {
        data.bookTime = data.bookTime.format()
      } else {
        data.bookTime = dayjs().format()
      }
      this.submiting = true
      order
        .create(data)
        .then((orderId) => {
          message.success(orderType === 0 ? '预订成功' : '下单成功')

          this.submiting = false
          if (orderType === 0) {
            this.$refs.preForm.reset()
          } else {
            this.$refs.arriveForm.reset()
          }

          this.onGetDataList((resp) => {
            this.tableOrderInfo = resp.filter(
              (x) => x.table.id === data.tableId
            )[0]

            this.tableInfo = this.tableOrderInfo.table
          })

          this.isPre = false
          this.isArrive = false
          this.orderId = orderId
        })
        .catch((e) => {
          console.log(e)
          this.submiting = false
        })
    },

    onPay () {
      this.onGetDataList((resp) => {
        const tableOrder = resp.filter(
          (x) => x.table.id === this.tableInfo.id
        )[0]

        this.tableInfo = tableOrder.table
        this.tableOrderInfo = tableOrder
        if (this.tableInfo.state === 2) {
          this.orderId = tableOrder.order.id
          this.$refs.orderInfo.refresh(tableOrder.order.id)
        }
      })
    },

    onAddDish () {
      this.onGetDataList()
      this.$refs.orderInfo.refresh()
    },

    onRefresh () {
      this.onGetDataList()
      this.$refs.orderInfo.refresh()
    },

    onSplitOrder () {
      this.spliting = true
      order
        .splitOrder({
          orderId: this.tableOrderInfo.order.id,
          payAmount: this.splitPayAmount
        })
        .then((id) => {
          this.onGetDataList((resp) => {
            this.tableOrderInfo = resp.filter(
              (x) => x.table.id === this.tableInfo.id
            )[0]
          })
          this.showAAModal = false
          this.splitPayAmount = 0
          this.orderId = id
          this.$refs.orderInfo.refresh(id)
        }).catch(e => {
          this.spliting = false
        })
    }
  }
})
</script>

<style lang="less" scoped>
.table-content {
  position: relative;
  padding-top: 0.67rem;
  padding-bottom: 0.4rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .search-btns {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding-left: 0.14rem;
    align-items: center;
    .btn {
      cursor: pointer;
      margin-left: 0.12rem;
      width: 0.75rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      border: 1px solid #000;
      text-align: center;
      line-height: 0.4rem;
      &.all {
        background: #75b1ff;
        border-color: #167bff;
        color: #fff;
      }
      &.empty {
        border-color: #6cdb9d;
        background: #dff3dd;
      }
      &.prede {
        border-color: #ffcc00;
        background: #f9edbf;
      }
      &.eating {
        border-color: #ff4f4f;
        background: #f9d6d6;
      }
    }
  }

  .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background: rgba(0, 0, 0, 0.5);
  }

  .tables {
    width: 100%;
    height: 100%;
    padding-right: 0.3rem;
    position: relative;
    .table-list {
      background: #fff;
      border-radius: 0.15rem;
      box-shadow: 0 0.08rem 0.24rem rgba(220, 220, 220, 0.5);
      width: 100%;
      height: 100%;
      padding: 0.4rem 0.4rem 0.3rem 0.4rem;
      overflow: hidden;
      overflow-y: auto;
      position: relative;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #998675;
      }

      .grid-box {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        height: 0.46rem;
        span {
          color: #000;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          margin-right: 0.2rem;
          &:before {
            content: "";
            display: block;
            width: 0.1rem;
            height: 0.1rem;
            border-radius: 50%;
            margin-right: 0.1rem;
          }
          &.empty {
            &:before {
              background: #a8eba0;
            }
          }
          &.pre {
            &:before {
              background: #fcd63d;
            }
          }
          &.arrive {
            &:before {
              background: #ff4f4f;
            }
          }
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.08rem;
        margin-right: -0.08rem;
        li {
          padding-left: 0.08rem;
          padding-right: 0.08rem;
          padding-bottom: 0.6rem;

          a {
            display: block;
            width: 1.7rem;
            height: 1.25rem;
            background: #dff3dd;
            border-radius: 5px;
            border-top: 0.1rem solid #a8eba0;
            color: #333;
            overflow: hidden;
            position: relative;
            .li-title {
              font-size: 0.18rem;
              padding: 0 0.1rem;
              line-height: 0.4rem;
            }
            .li-waiter {
              padding-left: 0.1rem;
              font-size: 0.12rem;
            }
            .li-info {
              font-size: 0.12rem;
              bottom: 0.34rem;
              height: 0.2rem;
              .info-time {
                padding-right: 0.1rem;
              }
            }
            .li-info-2 {
              font-size: 0.16rem;
              height: 0.34rem;
              bottom: 0;
              .info-people {
                padding-left: 0.16rem;
              }
            }
            .li-info,
            .li-info-2 {
              display: flex;
              align-items: center;
              position: absolute;
              left: 0.1rem;
              width: 100%;
            }

            .qrcode{
              position: absolute;
              top: 10px;
              right: 10px;
              cursor: pointer;
              font-size: 30px;
            }

            &.state-0 {
            }
            &.state-1 {
              border-color: #fcd63d;
              background: #f9edbf;
            }
            &.state-2 {
              border-color: #ff4f4f;
              background: #f9d6d6;
            }
          }

          &.on {
            a {
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
            }
          }
        }

        &.on-fixed {
          li {
            position: relative;
            z-index: 9999;
            &.on {
              z-index: 1;
            }
          }
        }
      }
    }

    .table-info {
      width: 5.2rem;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0.8rem;

      .form-content {
        width: 4.5rem;
        height: 100%;
        background: #fff;
        border-radius: 0.15rem;
        box-shadow: 0 0.08rem 0.24rem rgba(220, 220, 220, 0.5);
        position: relative;
        z-index: 2;
        overflow: hidden;
        padding-bottom: 0.5rem;

        .pre-form {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          z-index: 1;
          background: #fff;
        }

        .pre-order-list {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          z-index: 2;
          background: rgba(0, 0, 0, 0.3);

          .pre-order {
            position: absolute;
            bottom: -100%;
            left: 0;
            background: #fff;
            width: 100%;
            max-height: 70%;
            overflow: hidden;
            overflow-y: auto;
            border-radius: 0.1rem 0.1rem 0 0;
            transition: all 0.3s ease;
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            /* 滚动槽 */
            &::-webkit-scrollbar-track {
              border-radius: 10px;
            }
            /* 滚动条滑块 */
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #998675;
            }
            ul {
              padding: 0.1rem;
              list-style: none;
              li {
                border: 1px solid #e2e2e2;
                border-radius: 5px;
                padding: 0.1rem 0 0 0;
                margin-bottom: 15px;
                font-size: 0.14rem;

                &:last-child {
                  margin: 0;
                }
                .li-title,
                .li-user,
                .li-combo {
                  display: flex;
                  justify-content: space-between;
                  padding: 0 0.1rem;
                  line-height: 0.3rem;
                  color: #666;
                }
                .li-title {
                  font-size: 0.18rem;
                  font-weight: 600;
                  color: #333;
                }
                .li-combo {
                  border-bottom: 1px solid #ebebeb;
                  .canbiao {
                    color: #ff4f4f;
                  }
                }
                .li-actions {
                  display: flex;
                  justify-content: center;
                  .action {
                    width: 50%;
                    height: 0.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-left: 1px solid #ebebeb;
                    a {
                      color: #999;
                    }
                    &:first-child {
                      border: none;
                    }
                  }
                }
              }
            }

            &.on {
              bottom: 0.5rem;
            }
          }
        }

        .form {
          height: 100%;
          overflow-y: auto;

          .pre-btn {
            width: 2.86rem;
            height: 0.56rem;
            background: #f9eec0;
            border-radius: 5px;
            text-align: center;
            line-height: 0.56rem;
            margin: 0.5rem auto 0;
            cursor: pointer;

            &.arrive {
              background: #f8d5d5;
            }
          }

          &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
          }

          /* 滚动槽 */
          &::-webkit-scrollbar-track {
            border-radius: 10px;
          }
          /* 滚动条滑块 */
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #998675;
          }
        }

        .bottom-btns {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.5rem;
          line-height: 0.5rem;
          background: #f5f3f1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          z-index: 3;

          .btn {
            border-left: 1px solid #ece8e4;
            height: 100%;

            a {
              color: #998675;
              font-size: 0.14rem;
              display: block;
              text-align: center;
            }

            &.btn-1 {
              flex-grow: 1;
            }
            &.btn-2 {
              flex-grow: 1.5;
            }
            &.btn-3 {
              flex-grow: 2;
            }
            &.btn-4 {
              flex-grow: 2.5;
            }

            span {
              margin-right: 5px;
              transition: all 0.2s ease;
            }

            .rotate {
              transform: rotate(90deg);
            }
            &:first-child {
              border: none;
            }

            &.disabled {
              cursor: not-allowed;
              color: #ccc;
            }
          }
        }
      }
      .other-btns {
        position: absolute;
        top: 0.23rem;
        right: 0;
        .btn {
          width: 0.9rem;
          height: 0.8rem;
          border-radius: 0.16rem;
          background: #e7e3df;
          color: #998675;
          font-size: 0.18rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 0 0.15rem 0 0.25rem;
          margin-bottom: 0.15rem;
          text-align: center;
          cursor: pointer;
          position: relative;
          left: -1rem;
          &:nth-child(1) {
            transition: all 0.1s 0.2s ease;
          }
          &:nth-child(2) {
            transition: all 0.2s 0.2s ease;
          }
          &:nth-child(3) {
            transition: all 0.3s 0.2s ease;
          }
          &:nth-child(4) {
            transition: all 0.4s 0.2s ease;
          }
          &:nth-child(5) {
            transition: all 0.5s 0.2s ease;
          }
          &:nth-child(6) {
            transition: all 0.6s 0.2s ease;
          }
          &:nth-child(7) {
            transition: all 0.7s 0.2s ease;
          }

          &.disabled {
            cursor: not-allowed;
            color: #ccc;
          }
        }

        &.on {
          .btn {
            left: 0;
          }
        }
      }
    }

    &.on {
      padding-right: 6.17rem;
    }
  }
}

.info {
  background: #dff3dd;
  padding: 0.2rem 0;
  .table-title {
    font-size: 0.18rem;
    padding-left: 0.5rem;
    line-height: 0.5rem;
  }
}

.print-types {
  .btn {
    margin-bottom: 0.2rem;
    width: 100%;
    height: 0.5rem;
    background: #167bff;
    border-radius: 0.1rem;
    text-align: center;
    line-height: 0.5rem;
    color: #fff;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
      background: #75b1ff;
      box-shadow: 0 0 5px rgba(0, 0, 100, 0.2);
    }
    &:last-child {
      margin: 0;
    }
  }
}
</style>
