<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :footer="false"
    @close="onClose"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <img v-if="qrcode" :src="qrcode" alt="qrcode" style="height: 450px" />
      <div v-else style="width: 100%;height: 450px"></div>
      <a-button @click="createQrcode">重新生成二维码</a-button>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'
import { } from 'ant-design-vue'
import { TableClass } from '@/apis/table'

const api = new TableClass()
export default defineComponent({
  components: {},
  mixins: [],
  emits: ['ok'],
  setup () {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')
    const qrcode = ref('')
    const table = ref()

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    return {
      title,
      qrcode,
      visible,
      loading,
      table,

      onClose
    }
  },

  methods: {
    open (formData) {
      this.table = formData
      this.qrcode = formData.qrcode
      this.title = formData.name + '二维码'
      if (!formData.qrcode) {
        this.createQrcode()
      }
      this.visible = true
    },

    createQrcode () {
      this.loading = true
      api.qrcode(this.table.id).then(resp => {
        this.qrcode = resp
        this.loading = false
      })
    }
  }
})
</script>

<style lang="less" scoped></style>
