<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="600"
    :height="500"
    :closeAble="false"
    :footer="false"
    @close="onClose"
  >
    <a-spin tip="Loading..." :spinning="spinning">
      <div class="account-order">
        <div class="order-info" v-if="orderDetail">
          <div class="order-info-box">
            <div class="info">
              <div class="control" v-if="orderDetail.customerName">
                <div class="label">预约姓名</div>
                <div class="value">
                  {{
                    orderDetail.customerName +
                    "(" +
                    ["未知", "先生", "女士"][orderDetail.customerSex] +
                    ")"
                  }}
                </div>
              </div>

              <div class="control" v-if="orderDetail.customerMobile">
                <div class="label">手机号码</div>
                <div class="value">{{ orderDetail.customerMobile }}</div>
              </div>

              <div class="control">
                <div class="label">就餐时间</div>
                <div class="value">
                  {{ $filters.formatDate(orderDetail.bookTime, "MM-DD HH:mm") }}
                </div>
              </div>

              <div class="control">
                <div class="label">就餐人数</div>
                <div class="value">{{ orderDetail.peopleCount }}</div>
              </div>

              <div class="control" v-if="orderDetail.customerRemark">
                <div class="label">就餐备注</div>
                <div class="value">{{ orderDetail.customerRemark }}</div>
              </div>
            </div>

            <div class="item title">
              {{ orderDetail.comboName }}
              <div class="item-right">
                ¥{{ orderDetail.comboPrice * orderDetail.peopleCount }}
              </div>
            </div>

            <div class="items">

              <div class="item" v-for="(v, k) in orderItems2" :key="k">
                <div>
                  <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
                  <a-tag color="orange" v-if="v.state === 1">赠菜</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems3" :key="k">
                <div>
                  <a-tag color="blue">酒水</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems4" :key="k">
                <div>
                  <a-tag color="red">损耗</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems5" :key="k">
                <div>
                  <a-tag color="red" v-if="v.targetOrderId === orderId">{{
                    v.fromTableName
                  }}</a-tag>
                  <a-tag color="red" v-else>{{ v.toTableName }}</a-tag>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div v-if="v.targetOrderId !== orderId">
                    ¥{{ v.sellingPrice }}
                  </div>
                  <div v-else>¥0</div>
                </div>
              </div>

              <div class="item" v-for="(v, k) in orderItems" :key="k">
                <div>
                  {{ v.dish.name }}
                  <span class="count">
                    <CloseOutlined style="font-size: 10px" />
                    {{ v.quantity }}
                  </span>
                </div>
                <div class="item-right">
                  <div>¥{{ v.sellingPrice }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="coupons">
            <div>优惠券</div>
            <div class="coupon-state">
              <span v-if="couponsList.length === 0" style="color: #ccc"
                >暂时没有可用优惠券</span
              >
              <a-tooltip v-else>
                <template #title>选择优惠券</template>
                <a @click.stop="$refs.chooseCoupons.open(orderDetail)">
                  <a-tag color="#f50" v-if="!usedCoupons"
                    >{{ couponsList.length }}张可用</a-tag
                  >
                  <span v-else>{{ usedCoupons.title }}</span>
                </a>
              </a-tooltip>
            </div>
          </div>

          <div class="caijin">
            <div>菜品金额</div>
            <div class="caijin-state">
              <span>¥</span>{{ orderDetail.caijinAmount }}
            </div>
          </div>

          <div class="total">
            <div>支付金额</div>
            <div class="price">
              <span class="rmb">¥</span>
              <b>{{ orderDetail.payAmount }}</b>
              <a-tooltip>
                <template #title>抹零设置</template>
                <a @click.stop="$refs.changeNum.open(0)">
                  <EditOutlined />
                </a>
              </a-tooltip>

              <div class="moling" v-if="orderDetail.wipeOffAmount">
                <span class="del">¥{{ orderDetail.orderAmount }}</span>
                <span class="moling">抹零{{ orderDetail.wipeOffAmount }}</span>
              </div>
              <div class="zhekou" v-if="orderDetail.disCount">
                <span class="del">¥{{ orderDetail.orderAmount }}</span>
                <span style="margin-left: 5px">
                  折扣：{{ orderDetail.disCount }}折
                </span>
              </div>
              <div class="moling" v-if="orderDetail.savedAmount">
                <span class="del">¥{{ orderDetail.orderAmount }}</span>
                <span class="moling">抵扣{{ orderDetail.savedAmount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="order-pay">
          <h1>选择支付方式：</h1>
          <div class="pay-type">
            <a-radio-group v-model:value="payType" @change="onChangePayType">
              <a-radio style="line-height: 30px" :value="1">会员支付</a-radio>
              <a-radio style="line-height: 30px" :value="2">全民付</a-radio>
              <a-radio style="line-height: 30px" :value="3">现金支付</a-radio>
              <a-radio style="line-height: 30px" :value="4">扫码支付</a-radio>
              <a-radio style="line-height: 30px" :value="5">刷卡支付</a-radio>
              <a-radio style="line-height: 30px" :value="6">挂账</a-radio>
              <a-radio style="line-height: 30px" :value="10">会员卡支付</a-radio>
              <a-radio style="line-height: 30px" :value="11">其他</a-radio>
            </a-radio-group>
          </div>

          <div class="type-box">
            <div class="box" v-if="payType === 1">
              <h2>账户信息</h2>
              <a-spin tip="Loading..." :spinning="spinning2">
                <div v-if="finAccount">
                  <div class="form-control">
                    <div class="label">手机号码</div>
                    <div class="value">
                      <a-input
                        v-model:value="user.mobile"
                        style="width: 150px"
                        allowClear
                      >
                        <template #suffix>
                          <a-tooltip>
                            <template #title>查询账户</template>
                            <a @click.stop="onGetFinAccountWhenChangeMobile">
                              <SearchOutlined />
                            </a>
                          </a-tooltip>
                        </template>
                      </a-input>
                    </div>
                  </div>

                  <div class="form-control">
                    <div class="label">账户姓名</div>
                    <div class="value">
                      {{ user.name }}
                    </div>
                  </div>

                  <div class="form-control">
                    <div class="label">账户余额</div>
                    <div class="value">¥{{ finAccount.balance }}</div>
                  </div>
                </div>
              </a-spin>

              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :disabled="
                    !finAccount || finAccount.balance < orderDetail.payAmount
                  "
                  :loading="paying"
                  @click="onBalance(3)"
                >
                  余额支付
                </a-button>
                <a-button
                  type="primary"
                  style="margin-top: 15px"
                  block
                  :loading="paying"
                  @click="onWaitingBalance"
                >
                  余额扫码支付
                </a-button>
              </div>
            </div>
            <div class="box" v-if="payType === 2">
              <a-alert
                message="注意：扫码支付需要用户使用支付宝、微信等线上支付方式付款码支付"
                type="warning"
              />
              <a-alert
                style="margin-top: 15px"
                message="点击“扫码支付”按钮后提醒用户将付款码放在支付盒子上方扫码，确认支付成功后即可完成结算"
                type="info"
              />
              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :loading="paying"
                  @click="waitingPay"
                >
                  开始支付
                </a-button>
              </div>
            </div>
            <div class="box" v-if="payType === 3">
              <a-alert
                style="margin-top: 15px"
                message="点击“现金支付”，确认金额无误后点击客户已支付即可完成订单结算"
                type="info"
              />
              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :loading="paying"
                  @click="waitingPayCash(5)"
                >
                  现金支付
                </a-button>
              </div>
            </div>

            <div class="box" v-if="payType === 4">
              <a-alert
                style="margin-top: 15px"
                message="点击“扫码支付”，确认金额无误后点击客户已支付即可完成订单结算"
                type="info"
              />
              <div class="pay-type">
                <a-radio-group v-model:value="scanType">
                  <a-radio :value="9">
                    <div class="item">
                      <icon-font
                        type="icon-04384530"
                        style="font-size: 30px; color: #f82525"
                      />
                      <div style="margin-left: 10px">齐商银行</div>
                    </div>
                  </a-radio>

                  <a-radio :value="6">
                    <div class="item">
                      <AlipayCircleOutlined
                        style="font-size: 30px; color: #027aff"
                      />
                      <div style="margin-left: 10px">支付宝扫码</div>
                    </div>
                  </a-radio>

                  <a-radio :value="7">
                    <div class="item">
                      <WechatOutlined style="font-size: 30px; color: #04d066" />
                      <div style="margin-left: 10px">微信扫码</div>
                    </div>
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :loading="paying"
                  @click="waitingPayCash(scanType)"
                >
                  确认支付
                </a-button>
              </div>
            </div>

            <div class="box" v-if="payType === 5">
              <a-alert
                style="margin-top: 15px"
                message="点击“刷卡支付”，确认金额无误后点击客户已支付即可完成订单结算"
                type="info"
              />
              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :loading="paying"
                  @click="waitingPayCash(4)"
                >
                  刷卡支付
                </a-button>
              </div>
            </div>

            <div class="box" v-if="payType === 6">
              <h2>账户信息</h2>
              <a-spin tip="Loading..." :spinning="spinning2">
                <div v-if="finAccount">
                  <div class="form-control">
                    <div class="label">手机号码</div>
                    <div class="value">
                      <a-input
                        v-model:value="user.mobile"
                        style="width: 150px"
                        allowClear
                      >
                        <template #suffix>
                          <a-tooltip>
                            <template #title>查询账户</template>
                            <a @click.stop="onGetFinAccountWhenChangeMobile">
                              <SearchOutlined />
                            </a>
                          </a-tooltip>
                        </template>
                      </a-input>
                    </div>
                  </div>

                  <div class="form-control">
                    <div class="label">账户姓名</div>
                    <div class="value">
                      {{ user.name }}
                    </div>
                  </div>

                  <div class="form-control">
                    <div class="label">账户余额</div>
                    <div class="value">¥{{ finAccount.balance }}</div>
                  </div>

                  <div class="form-control">
                    <div class="label">挂账金额</div>
                    <div class="value">¥{{ orderDetail.payAmount }}</div>
                  </div>

                  <div class="form-control">
                    <div class="label">担保人</div>
                    <div class="value" style="width: 150px">
                      <s-select
                        v-model:value="guaranteeUserId"
                        title="客户经理"
                        :showTitle="false"
                        :options="guaranteeUsers"
                      />
                    </div>
                  </div>
                </div>
              </a-spin>

              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :loading="paying"
                  :disabled="user.mobile === ''"
                  @click="onBalance(8)"
                >
                  确定
                </a-button>
              </div>
            </div>

            <div class="box" v-if="payType === 10">
              <h2>会员卡信息</h2>
              <a-spin tip="Loading..." :spinning="spinning2">
                <div v-if="finAccount">
                  <div class="form-control">
                    <div class="label">会员卡号</div>
                    <div class="value">
                      <a-input
                        v-model:value="cardNumber"
                        style="width: 150px"
                        allowClear
                      >
                        <template #suffix>
                          <a-tooltip>
                            <template #title>查询</template>
                            <a @click.stop="onGetMemberCardInfo">
                              <SearchOutlined />
                            </a>
                          </a-tooltip>
                        </template>
                      </a-input>
                    </div>
                  </div>
                  <div
                    v-if="
                      memberCard && memberCard.customerMobile !== '88888888888'
                    "
                  >
                    <div class="form-control">
                      <div class="label">姓名</div>
                      <div class="value">
                        {{ memberCard.customerName }}
                        {{ ["未知", "先生", "女士"][memberCard.customerSex] }}
                      </div>
                    </div>

                    <div class="form-control">
                      <div class="label">手机号</div>
                      <div class="value">
                        {{ memberCard.customerMobile }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      memberCard && memberCard.customerMobile === '88888888888'
                    "
                  >
                    <div class="form-control">
                      <div class="label">姓名</div>
                      <div class="value">
                        <a-input
                          v-model:value="memberName"
                          style="width: 150px"
                          allowClear
                        />
                      </div>
                    </div>
                    <div class="form-control">
                      <div class="label">手机号</div>
                      <div class="value">
                        <a-input
                          v-model:value="memberMobile"
                          style="width: 150px"
                          allowClear
                        />
                      </div>
                    </div>
                    <div class="form-control">
                      <div class="label">性别</div>
                      <div class="value">
                        <s-radio
                          v-model:value="memberSex"
                          style="width: 150px"
                          :options="[
                            { label: '男', value: 1 },
                            { label: '女', value: 2 },
                          ]"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-control" v-if="memberCard">
                    <div class="label">会员卡余额</div>
                    <div class="value">¥{{ memberCard.balance }}</div>
                  </div>
                </div>
              </a-spin>

              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :disabled="
                    !memberCard || memberCard.balance < orderDetail.payAmount
                  "
                  :loading="paying"
                  @click="onPayByCard"
                >
                  会员卡支付
                </a-button>
              </div>
            </div>

            <div class="box" v-if="payType === 11">
              <a-alert
                style="margin-top: 15px"
                message="团购、外卖核销后选择支付方式"
                type="info"
              />
              <div class="pay-type" style="height: 200px;overflow-x: hidden;overflow-y: auto">
                <a-radio-group v-model:value="scanType">
                  <a-radio :value="12">
                    <div class="item">
                      <icon-font
                        type="icon-meituan1"
                        style="font-size: 30px; color: #f82525"
                      />
                      <div style="margin-left: 10px">美团团购</div>
                    </div>
                  </a-radio>

                  <a-radio :value="13">
                    <div class="item">
                      <icon-font
                        type="icon-douyin"
                        style="font-size: 30px; color: #f82525"
                      />
                      <div style="margin-left: 10px">抖音团购</div>
                    </div>
                  </a-radio>

                  <a-radio :value="14">
                    <div class="item">
                      <icon-font
                        type="icon-meituan"
                        style="font-size: 30px; color: #f82525"
                      />
                      <div style="margin-left: 10px">美团外卖</div>
                    </div>
                  </a-radio>

                  <a-radio :value="15">
                    <div class="item">
                      <icon-font
                        type="icon-elemo"
                        style="font-size: 30px; color: #f82525"
                      />
                      <div style="margin-left: 10px">饿了么外卖</div>
                    </div>
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="pay-btns-bottom">
                <a-button
                  type="primary"
                  style="margin-top: 25px"
                  block
                  :loading="paying"
                  @click="waitingPayCash(scanType)"
                >
                  确认支付
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>

    <ChangeNum
      ref="changeNum"
      :title="'填写抹零金额，最多' + molingMax + '元'"
      label="抹零"
      :min="0"
      :max="molingMax"
      @ok="onMoling"
    />

    <ChooseCoupons
      ref="chooseCoupons"
      :list="couponsList"
      @ok="onCouponsUsed"
    />
  </e-modal>
</template>

<script>
import { defineComponent, ref, createVNode, reactive } from 'vue'
import {
  ExclamationOutlined,
  CloseOutlined,
  SearchOutlined,
  EditOutlined,
  AlipayCircleOutlined,
  WechatOutlined
} from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import form from '@/mixins/form'
import { OrderClass, UnionPayClass } from '@/apis/order'
import { FinAccountClass } from '@/apis/fin'
import { ConfigClass } from '@/apis/config'
import { CustomerClass } from '@/apis/user'
import { addEvent, removeEvent } from '@/utils'
import ChangeNum from '@/components/forms/ChangeNum'

import ChooseCoupons from './ChooseCoupons'

const order = new OrderClass()
const fin = new FinAccountClass()
const union = new UnionPayClass()
const config = new ConfigClass()
const customer = new CustomerClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  components: {
    CloseOutlined,
    SearchOutlined,
    EditOutlined,
    ChangeNum,
    AlipayCircleOutlined,
    WechatOutlined,
    ChooseCoupons
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const spinning = ref(false)
    const spinning2 = ref(false)
    const paying = ref(false)
    const visible = ref(false)
    const title = ref()
    const orderDetail = ref()
    const finAccount = ref()
    const orderId = ref('')

    const payCode = ref('')
    const balanceCode = ref('')
    const timer = ref(0)
    const timer2 = ref(0)
    const scanType = ref(6)

    const orderItems = ref([])
    const orderItems2 = ref([])
    const orderItems3 = ref([])
    const orderItems4 = ref([])
    const orderItems5 = ref([])

    const guaranteeUserId = ref('')
    const guaranteeUsers = ref([])

    const payType = ref(1)
    const molingMax = ref()
    const zhekouMin = ref()

    const couponsList = ref([])

    const user = ref({
      name: '',
      mobile: ''
    })

    const radioStyle = reactive({
      display: 'flex'
    })

    const onClose = () => {
      removeEvent(window, 'keydown', eventFun)
      removeEvent(window, 'keydown', eventFun2)
      finAccount.value = null
      spinning.value = false
      spinning2.value = false
      loading.value = false
      paying.value = false
      visible.value = false
    }

    const onGetOrder = (id) => {
      spinning.value = true
      order.detail(id).then((resp) => {
        orderDetail.value = resp

        // 必点品
        orderItems.value = resp.orderItems.filter((x) => x.dish.isMust)
        // 加菜或赠菜
        orderItems2.value = resp.orderItems.filter((x) => x.type === 4)
        // 酒水
        orderItems3.value = resp.orderItems.filter((x) => x.type === 2)
        // 损耗
        orderItems4.value = resp.orderItems.filter((x) => x.type === 3)
        orderItems5.value = resp.orderItems.filter((x) => x.type === 5)

        onGetFinAccount(resp.customerMobile)
        title.value = '“' + resp.tableName + '”订单结算'
        spinning.value = false
      })
    }

    const waitingPay = () => {
      paying.value = true
      addEvent(window, 'keydown', eventFun)

      Modal.warning({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请提醒用户将支付宝、微信、云闪付等付款码放置在全民付盒子上方扫码'
        ),
        okText: '取消支付',
        onOk () {
          paying.value = false
          removeEvent(window, 'keydown', eventFun)
        }
      })
    }

    const eventFun = (e) => {
      if (e.key !== 'Enter') {
        if (e.key !== 'Shift') {
          payCode.value += e.key
        }
      }
    }

    const waitingPayCash = (channel) => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请先确认客户是否已经支付'
        ),
        okText: '客户已支付',
        onOk () {
          order.orderChannel(orderId.value, channel).then(() => {
            message.success('吧台确认支付成功')
            onClose()
            emit('ok')
          })
        },

        onCancel () {
          loading.value = false
        }
      })
    }

    const onGetCouponsList = (customerId) => {
      console.log(customerId)
      customer.getCoupons(customerId).then((resp) => {
        couponsList.value = resp
      })
    }

    const onGetFinAccount = (customerMobile) => {
      fin
        .detailBymobile(customerMobile)
        .then((resp) => {
          finAccount.value = resp

          user.value.name =
            resp.customerName +
            '(' +
            ['未知', '先生', '女士'][resp.customerSex] +
            ')'
          user.value.mobile = resp.customerMobile

          onGetCouponsList(resp.customerId)
          spinning2.value = false
        })
        .catch(() => {
          finAccount.value = { balance: 0 }
          user.value.name = '账户不存在'
          user.value.mobile = ''
          spinning2.value = false
        })
    }

    const onGetFinAccountWhenChangeMobile = () => {
      spinning2.value = true
      onGetFinAccount(user.value.mobile)
    }

    const onBalance = (e) => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:#333;'
          },
          [
            createVNode(
              'div',
              { style: 'font-size: 14px' },
              `扣款账户：${finAccount.value.customerMobile}`
            ),
            createVNode(
              'div',
              { style: 'font-size: 14px' },
              `账户余额：${finAccount.value.balance}`
            ),
            createVNode(
              'div',
              { style: 'color:red;' },
              `扣除金额：${orderDetail.value.payAmount}`
            )
          ]
        ),
        okText: '确认付款',
        onOk () {
          paying.value = true
          const data = {
            finAccountId: finAccount.value.id,
            orderId: orderDetail.value.id,
            money: orderDetail.value.payAmount,
            payChannel: e,
            guaranteeUserId: guaranteeUserId.value
          }
          if (!guaranteeUserId.value) {
            delete data.guaranteeUserId
          }
          fin
            .consume(data)
            .then(() => {
              message.success(e === 3 ? '会员余额付款成功' : '挂账付款成功')
              onClose()
              emit('ok')
            })
            .catch(() => {
              paying.value = false
            })
        },

        onCancel () {
          loading.value = false
        }
      })
    }

    const onWaitingBalance = () => {
      paying.value = true
      addEvent(window, 'keydown', eventFun2)

      Modal.warning({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          '请提醒用户将钱包付款码放置在全民付盒子上方扫码'
        ),
        okText: '取消支付',
        onOk () {
          paying.value = false
          removeEvent(window, 'keydown', eventFun2)
        }
      })
    }

    const eventFun2 = (e) => {
      if (e.key !== 'Enter') {
        if (e.key !== 'Shift') {
          balanceCode.value += e.key
        }
      }
    }

    const onGetConfig = () => {
      config.detail('Moling').then((resp) => {
        molingMax.value = Number(resp.value)
      })

      config.detail('Discount').then((resp) => {
        zhekouMin.value = Number(resp.value)
      })
    }

    const onChangePayType = (e) => {
      paying.value = false
      removeEvent(window, 'keydown', eventFun)
      removeEvent(window, 'keydown', eventFun2)
    }

    const memberName = ref('')
    const memberMobile = ref('')
    const memberSex = ref(1)
    const cardNumber = ref('')
    const memberCard = ref()
    const showMember = ref(false)
    const onGetMemberCardInfo = () => {
      showMember.value = false
      spinning2.value = true
      fin.getCard(cardNumber.value).then((resp) => {
        memberCard.value = resp

        if (resp.customerMobile === '88888888888') {
          showMember.value = true
        } else {
          showMember.value = false
        }
        spinning2.value = false
      })
    }
    const onPayByCard = () => {
      Modal.confirm({
        title: '注意！',
        icon: createVNode(ExclamationOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:#333;'
          },
          [
            createVNode(
              'div',
              { style: 'font-size: 14px' },
              `扣款卡号：${memberCard.value.cardNumber}`
            ),
            createVNode(
              'div',
              { style: 'font-size: 14px' },
              `会员卡余额：${memberCard.value.balance}`
            ),
            createVNode(
              'div',
              { style: 'color:red;' },
              `扣除金额：${orderDetail.value.payAmount}`
            )
          ]
        ),
        okText: '确认付款',
        onOk () {
          paying.value = true
          fin
            .cardPay({
              cardNumber: cardNumber.value,
              orderId: orderDetail.value.id,
              money: orderDetail.value.payAmount,
              Name: memberName.value,
              Mobile: memberMobile.value,
              Sex: memberSex.value
            })
            .then(() => {
              message.success('会员卡付款成功')
              onClose()
              emit('ok')
            })
            .catch(() => {
              paying.value = false
            })
        },

        onCancel () {
          loading.value = false
        }
      })
    }

    onGetConfig()

    return {
      showMember,
      memberSex,
      memberName,
      memberMobile,
      cardNumber,
      memberCard,
      visible,
      loading,
      orderDetail,
      title,
      user,
      orderId,
      spinning,
      spinning2,
      finAccount,
      payCode,
      balanceCode,
      timer,
      timer2,
      orderItems,
      orderItems2,
      orderItems3,
      orderItems4,
      orderItems5,
      payType,
      paying,
      molingMax,
      zhekouMin,
      scanType,
      radioStyle,
      couponsList,
      guaranteeUsers,
      guaranteeUserId,

      onClose,
      waitingPay,
      onWaitingBalance,
      onGetOrder,
      onGetFinAccount,
      onBalance,
      waitingPayCash,
      onGetFinAccountWhenChangeMobile,
      onGetConfig,
      onChangePayType,
      onGetMemberCardInfo,
      onPayByCard
    }
  },

  created () {
    this.$watch('payCode', (e) => {
      clearInterval(this.timer)
      this.timer = setTimeout(() => {
        console.log(e)
        if (e) {
          this.pay(e)
        }
      }, 500)
    })

    this.$watch('balanceCode', (e) => {
      clearInterval(this.timer2)
      this.timer2 = setTimeout(() => {
        console.log(e)
        if (e) {
          this.payBalance(e)
        }
      }, 500)
    })
  },

  computed: {
    usedCoupons () {
      const list = this.couponsList
      const order = this.orderDetail
      const filter = list.filter((x) => x.id === order.couponsId)
      return filter.length > 0 ? filter[0] : null
    }
  },

  methods: {
    open (orderId, tableOrderInfo) {
      this.orderId = orderId

      this.payCode = ''
      this.balanceCode = ''

      this.user.name =
        tableOrderInfo.order.customerName +
        '(' +
        ['未知', '先生', '女士'][tableOrderInfo.order.customerSex] +
        ')'
      this.user.mobile = tableOrderInfo.order.customerMobile
      this.onGetOrder(orderId)
      this.getGuaranteeUsers()
      this.visible = true
    },

    async getGuaranteeUsers () {
      const list =
        this.$store.state.data.ALL_HANDLERS.length > 0
          ? this.$store.state.data.ALL_HANDLERS
          : await this.$store.dispatch('GetAllHandlers')
      const vlist = []
      list.map((e) => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.guaranteeUsers = vlist
    },

    pay (code) {
      this.spinning = true
      this.paying = true
      union
        .boxPay({
          orderId: this.orderId,
          payCode: code
        })
        .then(() => {
          this.$message.success('全民付扫码支付成功')
          this.onClose()
          this.$emit('ok')
        })
        .catch(() => {
          this.payCode = ''
          this.$message.error('支付失败')
          this.spinning = false
          this.paying = false
        })
    },

    payBalance (code) {
      this.spinning = true
      this.paying = true
      fin
        .boxPay({
          orderId: this.orderId,
          payCode: code
        })
        .then(() => {
          this.$message.success('会员余额扫码支付成功')
          this.onClose()
          this.$emit('ok')
        })
        .catch(() => {
          this.balanceCode = ''
          this.$message.error('支付失败')
          this.spinning = false
          this.paying = false
        })
    },

    onMoling (e) {
      order.moling(this.orderId, e).then(() => {
        this.onGetOrder(this.orderId)
        this.$refs.changeNum.handleClose()
      })
    },

    onCouponsUsed (e) {
      order.youhuiquan(this.orderId, e.coupons.id, e.count).then(() => {
        this.onGetOrder(this.orderId)
        this.$refs.chooseCoupons.onClose()
      })
    }
  }
})
</script>

<style lang="less" scoped>
.account-order {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 470px;
  .order-info {
    width: 265px;
    height: 470px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #1890ff;

    .order-info-box {
      height: 360px;
      overflow: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #d3eaff;
      }
    }

    .info {
      background: #d3eaff;
      padding: 10px 5px;

      .control {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
        .label {
          color: #999;
        }
        .value {
          color: #333;
          font-weight: 600;
        }
      }
    }

    .item {
      padding: 5px;
      font-size: 12px;
      height: 40px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #666;

      .count {
        font-size: 10px;
      }

      .item-right {
        width: 40px;
      }

      &.title {
        background: #f8f8f8;
        border-bottom: 3px solid #ebebeb;
        font-size: 14px;
        color: #333;
        font-weight: 600;
        padding: 5px 10px;
      }
    }

    .items {
      padding: 0 5px;
    }

    .coupons,
    .caijin {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      background: #ecf6ff;
      padding: 0 10px;

      .coupon-state {
        font-size: 12px;
      }
    }
    .caijin {
      background: #e0effc;
      .caijin-state {
        font-size: 18px;
        color: #f50;
        text-align: right;
        font-weight: 600;
        span {
          font-size: 12px;
        }
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background: #d3eaff;
      padding: 0 10px;
      .price {
        font-size: 20px;
        color: #f50;
        text-align: right;

        .rmb {
          font-size: 12px;
        }

        .del {
          text-decoration: line-through;
          font-size: 12px;
          color: #999;
        }

        .moling {
          font-size: 12px;
          color: #999;
          margin: 0 3px;
        }

        a {
          color: #333;
          font-size: 12px;
        }

        .zhekou {
          font-size: 12px;
          color: #999;
          margin: 0 3px;
        }
      }
    }
  }
  .order-pay {
    width: 275px;
    height: 470px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .pay-type {
      height: 90px;
    }

    .type-box {
      border: 1px solid #f50;
      border-radius: 5px;
      overflow: hidden;
      height: 350px;
      position: relative;

      .box {
        padding: 10px;
        .form-control {
          display: flex;
          justify-content: space-between;
          height: 42px;
          align-items: center;
          .label {
            color: #999;
          }
          .value {
            color: #333;
            font-weight: 600;
          }
        }

        .pay-btns-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 10px 30px 10px;
        }

        .pay-type {
          padding: 30px;

          .item {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
